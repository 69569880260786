<template>
  <div class="twitch"></div>
</template>

<script>
export default {
  name: 'Twitch',
}
</script>

<style scoped lang="scss"></style>
