<template>
  <div class="layout disc">
    <header>
      <div class="container">
        <h1>TargasBR.live | DeMarco's Decryptor Disk</h1>

        <nav>
          <div
            class="g-ytsubscribe"
            data-channel="TargasBR"
            data-layout="default"
            data-theme="dark"
            data-count="hidden"
          ></div>

          <a
            href="http://youtube.com/targasbr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="require('@/assets/youtube.svg')" alt="" />
          </a>
          <a
            href="http://twitch.tv/targasbr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="require('@/assets/twitch.svg')" alt="" />
          </a>
          <a
            href="http://discord.gg/csotbr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="require('@/assets/discord.svg')" alt="" />
          </a>
        </nav>
      </div>
    </header>

    <div class="content">
      <div class="wrapper">
        <button class="counter-clockwise" @click="counterClockwise($event)"></button>

        <div class="coin-wrapper">
          <form class="form">
            <label for="code">
              <span>Code:</span>
              <input type="text" class="code" name="code" v-model="code" />
            </label>

            <label for="result">
              <span>Result:</span>
              <input
                type="text"
                class="result"
                name="result"
                v-model="result"
              />
            </label>
          </form>

          <img class="silver" :src="require('@/assets/silver.png')" />
          <img class="gold" ref="gold" :src="require('@/assets/gold.png')" />
        </div>

        <button class="clockwise" @click="clockwise($event)"></button>
      </div>
    </div>
  </div>
</template>

<script src="https://apis.google.com/js/platform.js"></script>

<script>
export default {
  name: 'Disc',

  components: {},

  async mounted() {},

  data() {
    return {
      current: 0,
      silver: ['a','c','w','e','p','i','j','t','d','s','k','x','y','l','r','g','x','f','n','q','m','b','u','h','v','o',],
      gold: ['z','a','c','4','h','e','f','g','d','i','u','8','b','m','n','3','9','q','s','r','w','5','o','7','6','y',],
      code: '',
      result: '',
    };
  },

  watch: {
    code() {
      this.decrypt()
    },
  },

  computed: {
    
  },

  methods: {
    decrypt() {
      this.code = this.code.toUpperCase()
      this.silver = this.silver.map((el) => { return el.toUpperCase(); })
      this.gold = this.gold.map((el) => { return el.toUpperCase(); })

      var arr = this.code.split('')
      var output = []

      for (var i = 0; i < arr.length; i++) {
        var c = this.code
        var letter = arr[i]
        var pos = this.gold.findIndex((c) => c === letter)
        output.push(this.silver[pos])
      }

      var res = output.join('')
      this.result = res
    },

    clockwise() {
      this.current += 1
      this.gold.unshift(this.gold.pop())
      this.spin()
    },

    counterClockwise() {
      this.current -= 1
      this.gold.push(this.gold.shift())
      this.spin()
    },

    spin() {
      if (this.current > 25) { this.current = 0 }
      if (this.current < 0) { this.current = 25 }

      var degrees = this.current * (360 / 26)

      this.$refs["gold"].style.transform = "translate(-50%, -50%)"
      this.$refs["gold"].style.transform += "rotate(" + degrees + "deg)"

      this.decrypt()
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 25px;

  .coin-wrapper {
    position: relative;
    width: 650px;
    height: 650px;

    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-image: url("~@/assets/fields.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      label {
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: #ffffff;
          margin-right: 10px;
        }

        input {
          text-transform: uppercase;
        }
      }
    }
  }
}

button {
  padding: 25px;
  margin: 10px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  border: 0;
  background: transparent;
  background-image: url("~@/assets/arrow.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.2);
  }

  &.counter-clockwise {
    transform: rotate(180deg);

    &:hover {
      transform: rotate(180deg) scale(1.2);
    }
  }
}

.silver,
.gold {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.silver {
  width: 500px;
  height: 500px;
  z-index: 1;
}

.gold {
  width: 650px;
  height: 650px;
  z-index: 0;
  transition: all 0.5s ease;
}
</style>
