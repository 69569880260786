import { render, staticRenderFns } from "./Twitch.vue?vue&type=template&id=4ce03f39&scoped=true&"
import script from "./Twitch.vue?vue&type=script&lang=js&"
export * from "./Twitch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce03f39",
  null
  
)

export default component.exports