import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Disc from '../views/Disc.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/disc',
    name: 'Disc',
    component: Disc
  },
]

const router = new VueRouter({
  routes
})

export default router
