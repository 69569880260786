<template>
  <div class="layout home">
    <header>
      <div class="container">
        <h1>TargasBR.live</h1>

        <nav>
          <a
            href="http://youtube.com/targasbr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="require('@/assets/youtube.svg')" alt="" />
          </a>
          <a
            href="http://twitch.tv/targasbr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="require('@/assets/twitch.svg')" alt="" />
          </a>
          <a
            href="http://discord.gg/csotbr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="require('@/assets/discord.svg')" alt="" />
          </a>
        </nav>
      </div>
    </header>

    <div class="content">
      <a href="https://targasbr.live/#/disc" class="button">
        Disco decodificador do DeMarco
      </a>

      <Twitch></Twitch>
      <YouTube></YouTube>
    </div>
  </div>
</template>

<script src="https://apis.google.com/js/platform.js"></script>

<script>
import Twitch from "@/components/Twitch.vue";
import YouTube from "@/components/YouTube.vue";

export default {
  name: "HomeView",

  components: {
    Twitch,
    YouTube,
  },

  data() {
    return {};
  },

  async mounted() {},

  watch: {},

  computed: {},

  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  height: calc(100vh - 200px);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: white;
    text-align: center;
  }
}

.button {
  background-image: url("~@/assets/button-bg.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  padding: 20px 30px;
  text-transform: uppercase;
  text-decoration: none;
  color: #FFFFFF;

  margin-bottom: 25px;
  transition: all .3s ease;

  &:hover {
    transform: scale(1.2);
  }
}
</style>
